import styled from "styled-components";
import css from "@styled-system/css";
import { variant } from "styled-system";

import Flex from "components/core/Grid/Flex";

export const ReactPlayerContainer = styled.div`
  position: relative;

  .overlay-name {
    z-index: 1000;
    position: absolute;
    top: 0px;
    width: 385px;
    height: 60px;
    background: transparent;
  }

  .overlay-share {
    z-index: 1000;
    position: absolute;
    top: 0px;
    right: 20px;
    width: 87px;
    height: 60px;
    background: transparent;
  }

  .overlay-youtube {
    background: transparent;
    position: absolute;
    bottom: 0px;
    right: 10px;
    width: 110px;
    height: 38px;
  }

  .overlay-youtube-watch {
    position: absolute;
    width: 185px;
    height: 55px;
    bottom: 0;
  }

  .overlay-more-videos {
    position: absolute;
    max-width: 570px;
    width: 100%;
    height: 159px;
    bottom: 59px;
    left: 10px;
  }
`;

export const Post = styled("article")(
  css({
    display: "flex",
    flexDirection: "column",
    bg: "paleGrey",
    width: "590px",
    borderRadius: "18px",
    overflow: "hidden",
    marginBottom: "37px",
    transition: "opacity .2s ease-out",
    button: {
      alignSelf: "center",
    },
    "&:hover": {
      opacity: 0.8,
    },
    "@media (max-width: 419px)": {
      width: "100%",
    },
    "@media (min-width: 420px) and (max-width: 767px)": {
      width: 400,
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      width: 420,
    },
  }),
  variant({
    variants: {
      inverted: {
        display: "flex",
        flexDirection: "column-reverse",
        "> :first-child::before": {
          content: "''",
          position: "absolute",
          top: "-20px",
          left: "5%",
          width: "90%",
          height: "1px",
          bg: "grayDefaultColor",
        },
        "&:hover": {
          opacity: 1,
        },
      },
    },
  }),
);

export const Header = styled(Flex)(
  css({
    display: "flex",
    flexDirection: "column",
    p: "20px",
  }),
);

export const Image = styled("img")(
  css({
    width: "590px",
    maxHeight: "500px",
    objectFit: "cover",
    cursor: "pointer",
    "@media (max-width: 419px)": {
      width: "100%",
    },
    "@media (min-width: 420px) and (max-width: 767px)": {
      width: 400,
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      width: 420,
    },
  }),
);

export const PostTitle = styled("h1")(
  css({
    mb: 4,
    fontSize: 4,
    color: "backText",
    fontWeight: "600",
    lineHeight: "30px",
    fontFamily: "heading",
    cursor: "pointer",
    "@media (max-width: 419px)": {
      fontSize: 3,
    },
  }),
);

export const Author = styled(Flex)(
  css({
    alignItems: "flex-start",
  }),
);

export const Username = styled("h2")(
  css({
    m: 0,
    mx: 2,
    fontSize: 18,
    lineHeight: "21px",
    fontFamily: "text",
    color: "secondaryColor",
    textTransform: "capitalize",
    "@media (max-width: 419px)": {
      fontSize: 2,
    },
  }),
);

export const Date = styled("h3")(
  css({
    mx: 2,
    fontSize: 1,
    color: "gray",
    fontFamily: "text",
  }),
);

export const ContainerTest = styled("div")(
  css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    svg: {
      width: "24px",
      height: "24px",
    },
  }),
);

export const TagStyle = styled("span")(
  css({
    ":first-of-type": {
      px: "8px",
      py: "5px",
      fontSize: 0,
      color: "white",
      lineHeight: "14px",
      borderRadius: "4px",
      bg: "secondaryColor",
      textTransform: "uppercase",
    },
    ":nth-of-type(2)": {
      width: "24px",
      display: "flex",
      fontSize: "13px",
      marginLeft: "-5px",
      borderRadius: "50%",
      alignItems: "center",
      color: "secondaryColor",
      justifyContent: "center",
      backgroundColor: "white",
      zIndex: "2",
    },
    ":nth-of-type(3), :nth-of-type(4)": {
      width: "24px",
      marginLeft: "-19px",
      borderRadius: "50%",
      bg: "rgba(125, 134, 161, .1)",
      zIndex: "1",
    },
  }),
);

export const FavPost = styled("button")(
  css({
    width: 19,
    height: 22,
    border: "0px",
    outline: "0px",
    marginLeft: "7px",
    backgroundColor: "transparent",

    svg: {
      marginBottom: "5px",
    },

    "svg path": {
      fill: "secondaryColor",
    },
  }),
);

export const FixedPost = styled("div")(
  css({
    width: 19,
    height: 22,
    border: "0px",
    outline: "0px",
    marginRight: "8px",
    backgroundColor: "transparent",

    svg: {
      marginBottom: "3px",
    },

    "svg path": {
      fill: "secondaryColor",
    },
  }),
);

export const UsersReactions = styled(Flex)(
  css({
    marginRight: 1,
    img: {
      width: "31px",
      border: "1px solid",
      borderColor: "white",
      "&:not(:last-child)": {
        marginRight: "-7px",
      },
    },
  }),
);

export const Counter = styled("span")(
  css({
    color: "#808080",
    marginRight: "10px",
    fontWeight: "400",
  }),
);

export const CommentsContainer = styled("div")(
  css({
    marginLeft: "7px",
  }),
);
